export default class SiliconHistoryElement extends HTMLElement {
  constructor() {
    super();
    this.touchEvent = 'touchend';
    this.selector = 'silicon-history a:not([data-route])';
  }

  connectedCallback() {
    this._setProperties();

    setTimeout(() => {
      this._startHistory();
    }, this.delay);
  }

  disconnectedCallback() {
    var touchEvent = this.touchEvent;
    var EVENT = 'ontouchend' in document ? touchEvent : 'click';
    var SELECTOR = this.selector;
    var DOCUMENT = $(document);
    DOCUMENT.off(EVENT, SELECTOR, this._onRequest.bind(this));
  }

  _setProperties() {
    var delay = this.getAttribute('delay');
    if (delay) this.delay = parseInt(delay);else this.delay = 500;
  }

  _startHistory() {
    var touchEvent = this.touchEvent;
    var REQUEST_EVENT = 'OnDocumentHistoryRequest';
    var EVENT = 'ontouchend' in document ? touchEvent : 'click';
    var SELECTOR = this.selector;
    var DOCUMENT = $(document);
    DOCUMENT.trigger(REQUEST_EVENT, {
      disableRedirectOnStart: true
    });
    DOCUMENT.on(EVENT, SELECTOR, this._onRequest.bind(this));
  }

  _onRequest(event) {
    var REQUEST_EVENT = 'OnDocumentRequest';
    var DOCUMENT = $(document);
    var target = $(event.currentTarget);
    var href = target.attr('href');

    if (href !== undefined && href !== '#') {
      var propagation = target.attr('data-propagation');
      if (propagation) event.stopPropagation();
      event.preventDefault(); //create data object

      var data = {
        method: 'get',
        href: href
      };
      $.each(target[0].attributes, function (i, att) {
        data[att.name.toCamelCase()] = att.value;
      });
      data.route = href; //trigger event

      DOCUMENT.trigger(REQUEST_EVENT, data);
    }
  }

}
customElements.define('silicon-history', SiliconHistoryElement);