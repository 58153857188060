import UtilityHelper from '../utility-helper/utility-helper';
export default class SearchFiltersElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;
    this.click = UtilityHelper.clickEvent;

    this._setProperties();

    this._setEvents();
  }

  _setProperties() {
    this.element = $(this);
    this._filters = null;
    this.toggleCss = 'hidden-filter-ui';
    this.toggleSelector = 'h5';
    this.toggleMobileSelector = '[data-toggle-mobile]';
    this.resetSelector = '[data-reset]';
  }

  _setEvents() {
    var click = this.click;
    this.element.on('paper-radio-group-changed', this._onRadioChange.bind(this));
    this.element.on(click, this.toggleSelector, this._onToggleClick.bind(this));
    this.element.on(click, this.toggleMobileSelector, this._onToggleMobileClick.bind(this));
    this.element.on(click, this.resetSelector, this._onResetClick.bind(this));
  }

  _onRadioChange(event) {
    this._fireEvent('change', {});
  }

  _onResetClick(event) {
    var radioGroups = this._getRadioGroups();

    $.each(radioGroups, function (index, radioGroup) {
      radioGroup.selectIndex(0);
    });

    this._fireEvent('reset', {});
  }

  _onToggleClick(event) {
    var target = event.currentTarget;
    var toggle = target.dataset.toggle;
    var active = target.dataset.active;
    if (active === 'true') this._minimizeFilter(target, toggle);else this._expandFilter(target, toggle);
  }

  _onToggleMobileClick(event) {
    var target = event.currentTarget;
    var active = target.dataset.active;
    if (active === 'true') this._hideFilters(target);else this._showFilters(target);
  }

  _expandFilter(target, toggle) {
    target.dataset.active = 'true';
    var $target = $(target);
    var expandIcon = $target.find('[data-expand-icon]');
    var minimizeIcon = $target.find('[data-minimize-icon]');
    expandIcon.addClass('hide');
    minimizeIcon.removeClass('hide');
    var ui = this.element.find('[data-toggle="' + toggle + '"]');
    ui.removeClass(this.toggleCss);
  }

  _minimizeFilter(target, toggle) {
    target.dataset.active = '';
    var $target = $(target);
    var expandIcon = $target.find('[data-expand-icon]');
    var minimizeIcon = $target.find('[data-minimize-icon]');
    expandIcon.removeClass('hide');
    minimizeIcon.addClass('hide');
    var ui = this.element.find('[data-toggle="' + toggle + '"]');
    ui.addClass(this.toggleCss);
  }

  _showFilters(target) {
    target.dataset.active = 'true';

    var filters = this._getFilters();

    filters.addClass('show-filters');

    var mobileIcon = this._getMobileIcon();

    mobileIcon.text('keyboard_arrow_up');
  }

  _hideFilters(target) {
    target.dataset.active = '';

    var filters = this._getFilters();

    filters.removeClass('show-filters');

    var mobileIcon = this._getMobileIcon();

    mobileIcon.text('keyboard_arrow_down');
  }

  _getFilters() {
    if (!this._filters) {
      this._filters = this.element.find('.filters');
    }

    return this._filters;
  }

  _getMobileIcon() {
    if (!this._mobileIcon) {
      this._mobileIcon = this.element.find('#mobileFilterIcon');
    }

    return this._mobileIcon;
  }

  _getRadioGroups() {
    return this.element.find('paper-radio-group');
  }

  _fireEvent(evt, data) {
    this.fire('search-filters-' + evt, data);
  }

}
customElements.define('search-filters', SearchFiltersElement);