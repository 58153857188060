import UtilityHelper from '../utility-helper/utility-helper';
import TransitionHelper from '../utility-helper/transition-helper';
import MutationObserver from '../mutation-observer/mutation-observer';
export default class MagicZoomElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.element = $(this);
    this.fire = UtilityHelper.fireEvent;
    this._transition = TransitionHelper.transition;

    this._setProperties();

    this._attachToDom();

    this._ready(this._initForScreen.bind(this));
  }

  _setProperties() {
    this._zoomPosition = '#zoom-placeholder';
    this._touchZoomPosition = 'inner';
    this._zoomOn = 'click';
    this._isZoomActive = false;
    this._figure = null;
    this._hint = null;
    this._screenType = null;
    this._initialized = false;
    this._magicZoom = null;
    this._zoomImage = null;
  }

  _ready(callback) {
    var intervalId = setInterval(() => {
      if (window.MagicZoom) {
        clearInterval(intervalId);
        callback();
      }
    }, 250);
  }

  _initForScreen() {
    var isMediaTouch = UtilityHelper.isMediaTouch;

    if (isMediaTouch) {
      MutationObserver.connect();
    }

    this._screenType = isMediaTouch ? 'touch' : 'desktop';
    if (isMediaTouch && !this.disableTouch) this._initComponent();else if (!this.disableDesktop) this._initComponent();else this.classList.add('hide');
  }
  /**
   *  Init magic zoom component
   * @private
   */


  _initComponent() {
    console.log('zoom image initialized');
    var isMediaTouch = UtilityHelper.isMediaTouch;

    this._setDataOptions();

    this._setZoomImage();

    if (!this.disableCssInit) {
      this.classList.remove('hide');
    }

    if (!isMediaTouch && !this.disableUpdateEvent) this._events(); //else if(this._mq.touch && this.touchUpdateEvent) this._events();

    this._getInternalElementsReference(() => {
      if (!this.disableHintFadeOut) this._clearHint();
      if (isMediaTouch) this._setChangedMutationObserver();
    });
  }

  _setChangedMutationObserver() {
    $(document).on('document-mutation-changed', this._onChangedMutation.bind(this));
  }
  /**
   *  Events
   * @private
   */


  _events() {
    $(document).on('item-image-selected', this._updateZoomImage.bind(this));
  }
  /**
   *  Update element MagicZoom with a new image
   * @param data {Object}
   * @private
   */


  _updateZoomImage(data) {
    var detail = data.detail;
    var zoomSrc = detail.zoomSrc;
    var src = detail.src;

    if (zoomSrc && this._initialized) {
      var element = this._magicZoom;
      element.setAttribute('href', zoomSrc);

      this._zoomImage.setAttribute('zoom-src', zoomSrc);

      this._zoomImage.setAttribute('src', src);

      MagicZoom.update(element, zoomSrc, src);
    } else if (detail.zoomSrc) {
      this._initialized = true;
      element.setAttribute('href', zoomSrc);

      this._zoomImage.setAttribute('zoom-src', zoomSrc);

      this._zoomImage.setAttribute('src', src);

      element.classList.add('MagicZoom');
      MagicZoom.start(element);
    }
  }
  /**
   *  Mutation Observer listener for "figure" element class attribute changes
   * @param changed {Object}
   * @private
   */


  _onChangedMutation(event) {
    var changed = event.detail.changed;
    var changedClass = changed.class;
    var figure = this._figure;

    if (changedClass) {
      changedClass.forEach(element => {
        if (element === figure) this._parseZoomEvents(element);
      });
    }
  }
  /**
   *  Figure element class atribute change handler; if .mz-active exists, element has been inner zoomed
   * @param element {Object}
   * @private
   */


  _parseZoomEvents(element) {
    if (element.classList.contains('mz-active')) {
      this._isZoomActive = true;

      this._fireEvent('in');
    } else if (this._isZoomActive) {
      this._isZoomActive = false;

      this._fireEvent('out');
    }
  }
  /**
   * Set internal element references
   * @param callback {Function}
   * @private
   */


  _getInternalElementsReference(callback) {
    var timeoutId = setInterval(() => {
      var hint = this.element.find('.mz-hint');

      if (hint[0]) {
        clearInterval(timeoutId);
        this._hint = hint;
        this._figure = this.element.find('figure')[0];
        callback();
      }
    }, 500);
  }
  /**
   *  Clear hint element handler
   * @private
   */


  _clearHint() {
    this._fadeOutHint(this._hint);
  }
  /**
   * Fade out hint element
   * @param element {Object}
   * @private
   */


  _fadeOutHint(element) {
    var delay = this.hintDuration;
    var transition = this._transition;
    setTimeout(function () {
      transition(element, {
        opacity: 0,
        duration: 450
      });
    }, delay);
  }
  /**
   *  Starts a ZoomMagic instance on the element. Attributes are based on screen type and web component props
   * @private
   */


  _setZoomImage() {
    var element = this._magicZoom;
    var src = this.src;
    var zoomSrc = this.zoomSrc;
    var screenType = UtilityHelper.screenType;

    if (screenType === 'large' && this.desktopWidth && zoomSrc) {
      zoomSrc = this._getNonQueryUrl(zoomSrc);
      zoomSrc += '?width=' + this.desktopWidth;
    } else if (screenType === 'medium' && this.tabletWidth && zoomSrc) {
      zoomSrc = this._getNonQueryUrl(zoomSrc);
      zoomSrc += '?width=' + this.tabletWidth;
    } else if (screenType === 'small' && this.phoneWidth && zoomSrc) {
      zoomSrc = this._getNonQueryUrl(zoomSrc);
      zoomSrc += '?width=' + this.phoneWidth;
    }

    if (zoomSrc) {
      this._initialized = true;
      element.setAttribute('href', zoomSrc);

      this._zoomImage.setAttribute('zoom-src', zoomSrc);

      element.classList.add('MagicZoom');

      try {
        MagicZoom.start(element);
      } catch (ex) {}
    }
  }

  _getNonQueryUrl(src) {
    src = src.split('?');
    return src[0];
  }
  /**
   *  Set element data options
   * @private
   */


  _setDataOptions() {
    var isMediaTouch = UtilityHelper.isMediaTouch;
    var options = '';
    var zoomPosition = this._zoomPosition;
    var touchZoomPosition = this._touchZoomPosition;
    var hint = this._hint;
    var zoomOn = this._zoomOn;
    if (!isMediaTouch) options += 'zoomPosition: ' + zoomPosition + ';';else options += 'zoomPosition: ' + touchZoomPosition + ';';
    options += 'hint: ' + hint + ';';
    options += 'zoomOn: ' + zoomOn;
    this._magicZoom.dataset.options = options;
  }
  /**
   *  Fires zoom event
   * @param evt {String}
   * @private
   */


  _fireEvent(evt) {
    this.fire('image-zoom-' + evt, {});
  }

  get src() {
    return this.getAttribute('src');
  }

  set src(val) {
    return this.setAttribute('src', val);
  }

  get zoomSrc() {
    return this.getAttribute('zoom-src');
  }

  set zoomSrc(val) {
    return this.setAttribute('zoom-src', val);
  }

  get desktopWidth() {
    return this.getAttribute('desktop-width');
  }

  get tabletWidth() {
    return this.getAttribute('desktop-width');
  }

  get phoneWidth() {
    return this.getAttribute('phone-width');
  }

  get phoneWidth() {
    return this.getAttribute('phone-width');
  }

  get disableHintFadeOut() {
    return this.getAttribute('disable-hint-fade-out');
  }

  get hintDuration() {
    var duration = this.getAttribute('hint-duration');
    if (duration) return parseInt(duration);else return 3500;
  }

  get disableTouch() {
    return this.getAttribute('disable-touch');
  }

  get disableDesktop() {
    return this.getAttribute('disable-desktop');
  }

  get errorSrc() {
    return this.getAttribute('error-src');
  }

  _attachToDom() {
    var src = this.src;
    var zoomSrc = this.zoomSrc;
    var dom = '<a id="magicZoom"><img src="' + src + '" data-image zoom-src="' + zoomSrc + '" id="zoomImage" onerror="onZoomImageError(this)"></a>';
    var $dom = $(dom);
    this.element.append($dom);
    this._magicZoom = this.querySelector('#magicZoom');
    this._zoomImage = this.querySelector('#zoomImage');

    window.onZoomImageError = t => {
      if (this.errorSrc) {
        t.src = this.errorSrc;
      }

      setTimeout(() => {
        var loading = document.querySelector('.mz-loading');

        if (loading) {
          loading.classList.remove('shown');
        }
      }, 3000);
    };
  }

}
customElements.define('magic-zoom', MagicZoomElement);