import UtilityHelper from '../utility-helper/utility-helper';
export default class UiInputAddOnElement extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() {
    return ['value', 'placeholder', 'readonly', 'disabled'];
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;
    this.click = UtilityHelper.clickEvent;

    this._attachToDom();

    this._setEvents();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.input && oldValue) {
      this.input.setAttribute(name, newValue);

      if ((name === 'readonly' || name === 'disabled') && (newValue === 'false' || newValue === false)) {
        this.input.removeAttribute(name);
      }
    }
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _attachToDom() {
    var input = `<input type="${this.type}" placeholder="${this.placeholder}" value="${this.defaultValue}" id="${this.id}" name="${this.name}" />`;
    var button = `<div class="ui-button"><i class="material-icons pointer">${this.icon}</i></div>`;
    var $dom = $(input + button);
    $(this).append($dom);
    this.input = this.querySelector('input');
    this.iconElement = this.querySelector('i');
    this.button = this.querySelector('.ui-button');

    if (this.defaultDisabled) {
      this.disabled = true;
    }

    if (this.defaultReadonly) {
      this.readonly = true;
    }

    if (this.iconColor) {
      this.iconElement.style.color = this.iconColor;
    }

    if (this.buttonBorderColor) {
      this.button.style.borderColor = this.buttonBorderColor;
    }

    if (this.buttonClass) {
      this.button.classList.add(this.buttonClass);
    }
  }

  _setEvents() {
    var onKeyPress = this._onKeyPress.bind(this);

    var onFocus = this._onFocus.bind(this);

    var onBlur = this._onBlur.bind(this);

    var onChange = this._onChange.bind(this);

    var onClick = this._onClick.bind(this);

    var input = this.input;

    if (!this.disableKeyPressEvent) {
      input.addEventListener('keypress', onKeyPress);
    }

    input.addEventListener('focus', onFocus);
    input.addEventListener('blur', onBlur);
    input.addEventListener('change', onChange);

    if (this.iconElement) {
      this.iconElement.addEventListener(this.click, onClick);
    }
  }

  _removeEvents() {
    var onKeyPress = this._onKeyPress.bind(this);

    var onFocus = this._onFocus.bind(this);

    var onBlur = this._onBlur.bind(this);

    var onChange = this._onChange.bind(this);

    var onClick = this._onClick.bind(this);

    var input = this.input;
    if (!input) return;

    if (!this.disableKeyPressEvent) {
      input.removeEventListener('keypress', onKeyPress);
    }

    input.removeEventListener('focus', onFocus);
    input.removeEventListener('blur', onBlur);
    input.removeEventListener('change', onChange);

    if (this.iconElement) {
      this.iconElement.removeEventListener(this.click, onClick);
    }
  }

  _onClick(event) {
    var value = this.value;
    this.fire('input-addon-submit', {
      value: value,
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onKeyPress(event) {
    if (event.charCode == 13 || event.charCode == 0) {
      var target = event.target;
      var value = target.value;
      event.preventDefault();
      this.fire('input-addon-submit', {
        value: value,
        target: this,
        name: this._name,
        id: this._id
      });
    }
  }

  _onFocus(event) {
    this.fire('input-addon-focus', {
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onBlur(event) {
    this.fire('input-addon-blur', {
      target: this,
      name: this._name,
      id: this._id
    });
  }

  _onChange(event) {
    this.fire('input-addon-change', {
      value: event.target.value,
      target: this,
      name: this._name,
      id: this._id
    });
  }

  get value() {
    return this.input.value;
  }

  set value(val) {
    this.input.value = val;
  }

  get defaultValue() {
    var value = this.getAttribute('data-value');
    if (value) return value;else return '';
  }

  get defaultDisabled() {
    var value = this.getAttribute('data-disabled');
    if (value) return true;else return false;
  }

  get defaultReadonly() {
    var value = this.getAttribute('data-readonly');
    if (value) return true;else return false;
  }

  get buttonBorderColor() {
    return this.getAttribute('button-border-color');
  }

  get name() {
    if (this._name) return this._name;
    var name = this.getAttribute('data-name');
    if (!name) name = 'name-' + UtilityHelper.id(4);
    this._name = name;
    return name;
  }

  get id() {
    if (this._id) return this._id;
    var id = this.getAttribute('data-id');
    if (!id) id = 'id-' + UtilityHelper.id(4);
    this._id = id;
    return id;
  }

  get type() {
    var type = this.getAttribute('type');
    if (!type) return 'text';else return type;
  }

  get placeholder() {
    return this.getAttribute('placeholder');
  }

  get icon() {
    var icon = this.getAttribute('icon');
    if (icon) return icon;else return '';
  }

  get iconColor() {
    return this.getAttribute('icon-color');
  }

  get buttonClass() {
    return this.getAttribute('button-class');
  }

  get readonly() {
    return this.input.getAttribute('readonly');
  }

  set readonly(val) {
    if (val) {
      this.input.setAttribute('readonly', 'true');
    } else {
      this.input.removeAttribute('readonly');
    }
  }

  get disabled() {
    return this.input.getAttribute('disabled');
  }

  set disabled(val) {
    if (val) {
      this.input.setAttribute('disabled', 'true');
    } else {
      this.input.removeAttribute('disabled');
    }
  }

  get disableKeyPressEvent() {
    return this.getAttribute('disable-keypress-event');
  }

  clear() {
    this.input.value = '';
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

}
customElements.define('ui-input-addon', UiInputAddOnElement);