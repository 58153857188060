import UtilityHelper from '../utility-helper/utility-helper';
import TransitionHelper from '../utility-helper/transition-helper';
import './carousel-indicators';
import './carousel-inner-container';
import './carousel-items';
import './carousel-item';
import './carousel-navigation';
export default class UiCarouselElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.element = $(this);
    this.fire = UtilityHelper.fireEvent;
    this._transition = TransitionHelper.transition;
    this._isTouch = UtilityHelper.isMediaTouch;
    this._isNativeTouch = UtilityHelper.isTouch;

    this._createDataStore();

    this._assignData();

    this._setInternalProperties();

    if (this.minLength) {
      this._carouselItems.style.width = this.minLength + 'px';
    }

    setTimeout(() => {
      this._initialize();
    }, 350);
  }

  _createDataStore() {
    this._data = {
      _store: new Map(),
      get: function get(key) {
        return this._store.get(key);
      },
      set: function set(key, val) {
        this._store.set(key, val);
      }
    };
  }

  _assignData() {
    this._data.set('innerContainer', null);

    this._data.set('innerContainerSelector', 'carousel-inner-container');

    this._data.set('size', 0);

    this._data.set('iteration', 0);

    this._data.set('prevNav', null);

    this._data.set('nextNav', null);

    this._data.set('readyState', false);

    this._data.set('timeoutId', null);

    this._data.set('intervalId', null);

    this._data.set('currentSlide', null);

    this._data.set('itemWidth', 0);

    this._data.set('itemMargin', 0);

    this._data.set('itemBorder', 0);

    this._data.set('item', 'carousel-item');

    this._data.set('visible', 'nav-visible');

    this._data.set('carouselItems', null);

    this._data.set('autoInterval', 4000);

    this._data.set('fadeAutoInterval', 8000);

    this._data.set('element', null);
  }

  _setInternalProperties() {
    this._suspended = false;
    this._auto - false;
    this._animation = "slide";
    this._currentOffset = 0;
    this._runningOffset = 0;
    this._swipeExecuting = false;
    this._itemsWidth = 0;
    this._carouselItems = this.querySelector('carousel-items');
    this._orientationEvent = UtilityHelper.orientationEvent;
    this._click = UtilityHelper.clickEvent;
    this.duration = 350;
  }

  _initialize() {
    this._setFingerEvents();

    this._initialization();

    this._onInitialization();
  }
  /**
     *
     * @private
     */


  _initialization() {
    var element = this.element; //get size=total number of slides

    this._data.set('size', element.find(this._data.get('item')).length); //get the current slide=1st element


    this._data.set('currentSlide', element.find(this._data.get('item') + ':first'));

    this._data.set('iteration', 0);

    var currentSlide = this._data.get('currentSlide');

    this._data.set('indicators', null);

    if (this.indicators) {
      this._initIndicatorNav();
    }
  }

  _onInitialization() {
    this._setDuration();

    var animation = this.animation;

    this._readyState();

    this._setInnerContainerVisibility();

    if (animation === 'fade' && this.auto) {
      this._initAutoInterval();
    } else {
      /* multi-slide or slide transition */
      if (animation === 'multi-slide') this._initMultiSlide();else if (animation === 'slide') this._initSlide();
      if (animation === 'slide' && this.auto) this._initAutoSlideInterval();
    }
  }
  /**
   * carousel component is ready to begin
   * @private
   */


  _readyState() {
    this._data.set('readyState', true);

    this._initContainers();

    this._initNav();

    this._events();
  }
  /**
   *
   * @private
   */


  _initAutoInterval() {
    setTimeout(() => {
      this._autoFadeSlide();
    }, this.initInterval);
  }
  /**
   *
   * @private
   */


  _initAutoSlideInterval() {
    var MAX_COUNT = this.maxIntervals;
    var count = 0;
    var intervalId = setInterval(() => {
      this._data.set('intervalId', intervalId);

      this._setIndicatorNav();

      this._nextSlide();

      if (MAX_COUNT) {
        count++;
        if (count > MAX_COUNT) clearInterval(intervalId);
      }
    }, this.autoInterval);
  }
  /**
   *
   * @private
   */


  _initSlide() {
    /* disable prev nav */
    var prevNav = this._data.get('prevNav');

    var nextNav = this._data.get('nextNav');

    this._hideNav(prevNav);

    var size = this._data.get('size');

    if (size < 2) this._hideNav(nextNav);
  }
  /**
   * init multi-slide
   * @private
   */


  _initMultiSlide() {
    var ele = this.element;

    var size = this._data.get('size');

    var li = ele.find(this._data.get('item') + ':first');

    this._data.set('itemWidth', li.outerWidth());

    this._data.set('itemMargin', parseInt(li.css('margin-right'), 10));
    /* disable prev nav */


    var prevNav = this._data.get('prevNav');

    var nextNav = this._data.get('nextNav');

    this._hideNav(prevNav);
    /* check to disable next nav */


    if (size < 2) this._hideNav(nextNav);else {
      //if no content to slide
      if (!this._contentToSlide()) this._hideNav(nextNav);
    }

    this._setInnerContainerBorder();
  }
  /**
   * private method that sets the containers
   * @private
   */


  _initContainers() {
    var element = this.element;

    var containerSelector = this._data.get('innerContainerSelector');

    this._data.set('innerContainer', element.find(containerSelector));

    this._data.set('itemsContainer', element.find('carousel-items'));
  }

  _setFingerEvents() {
    if (!this._isNativeTouch) return false;

    if (!window._FINGER_EVENTS_COUNT) {
      window._FINGER_EVENTS_COUNT = 1;
    } else window._FINGER_EVENTS_COUNT += 1;

    window._FINGER_EVENTS = true;
  }

  _killFingerEvents() {
    if (!this._isNativeTouch) return false;
    if (window._FINGER_EVENTS_COUNT) window._FINGER_EVENTS_COUNT -= 1;
    if (!window._FINGER_EVENTS_COUNT) window._FINGER_EVENTS = false;
  }

  /**
   * set inner container to visible
   * @private
   */
  _setInnerContainerVisibility() {
    var innerContainer = this._data.get('innerContainer');

    if (innerContainer) innerContainer.addClass('visible');else {
      this._initContainers();

      innerContainer = this._data.get('innerContainer');
      innerContainer.addClass('visible');
    }
  }
  /**
   * for multi-slide, set a transparent border class, if no existing border
   * @private
   */


  _setInnerContainerBorder() {
    var innerContainer = this._data.get('innerContainer');

    var border = parseInt(innerContainer.css('border'), 10);
    if (border === 0) innerContainer.addClass('multi-border');
  }
  /**
   * private method that handles transition to next slide(css prop transitioned is margin-left)
   * @private
   */


  _nextSlide() {
    var ele = this._data.get('itemsContainer');

    var iteration = this._data.get('iteration');

    var contentToSlide = this._contentToSlide();

    if (contentToSlide) {
      //if the left offset position of last-child is greater than container width, we can do a next slide
      var slideDistanceFactor = this._slideDistanceFactor('first');

      iteration++;

      this._data.set('iteration', iteration);

      var marginLeft = -1 * iteration * slideDistanceFactor;
      var offset = parseInt(this._currentOffset);
      marginLeft = marginLeft - offset;
      marginLeft = marginLeft - this._runningOffset;
      this._runningOffset = this._runningOffset + offset;
      marginLeft = marginLeft.toString() + 'px';
      var evtData = {
        slideIndex: iteration
      };

      this._fireEvent('sliding', evtData);

      this._transition(ele, {
        'margin-left': marginLeft,
        duration: this.duration,
        easing: this.easing
      }, () => {
        this._swipeExecuting = false;
        /* remove any disabled css markup from nav */

        var prevNav = this._data.get('prevNav');

        var nextNav = this._data.get('nextNav');

        if (prevNav[0]) this._showNav(prevNav);
        /* check if nextNav needs to be disabled */

        if (!this._contentToSlide()) this._hideNav(nextNav);

        this._fireEvent('slide', evtData);
      });
    } else {
      var intervalId = this._data.get('intervalId');

      if (intervalId) clearInterval(intervalId);
    }
  }

  _getCarouselItems() {
    var items = this._data.get('carouselItems');

    if (!items) items = this.element.find('carousel-item');
    return items;
  }

  _getSize() {
    var items = this._getCarouselItems();

    return items.length;
  }
  /**
   * private method that handles transition to prev slide (css prop transitioned is margin-left)
   * @private
   */


  _prevSlide() {
    var ele = this._data.get('itemsContainer');

    var iteration = this._data.get('iteration');

    if (iteration > 0) {
      //if first-child is not the first visible position, we can do a previous slide
      var slideDistanceFactor = this._slideDistanceFactor('first');

      iteration--;

      this._data.set('iteration', iteration);

      var marginLeft = -1 * iteration * slideDistanceFactor;
      var offset = parseInt(this._currentOffset);
      this._runningOffset = this._runningOffset + offset;
      marginLeft = marginLeft - this._runningOffset;
      marginLeft = marginLeft.toString() + 'px';
      var evtData = {
        slideIndex: iteration
      };

      this._fireEvent('sliding', evtData);

      this._transition(ele, {
        'margin-left': marginLeft,
        duration: this.duration,
        easing: this.easing
      }, () => {
        this._swipeExecuting = false;
        /* remove any disabled css markup from nav */

        var prevNav = this._data.get('prevNav');

        var nextNav = this._data.get('nextNav');

        this._showNav(nextNav);
        /* check if prevNav needs to be disabled */


        if (iteration === 0) this._hideNav(prevNav);

        this._fireEvent('slide', evtData);
      });
    }
  }
  /**
   * private method handles both next and prev slide for fade transition
   * @param slideIndex
   * @private
   */


  _FadeSlide(slideIndex) {
    var items = this._getCarouselItems();

    var nextSlide_ = items[slideIndex];
    var nextSlide = $(nextSlide_);

    var currentSlide = this._data.get('currentSlide');

    currentSlide.css({
      position: 'absolute',
      display: 'block',
      float: 'none',
      'z-index': 1
    });
    nextSlide.css({
      position: 'relative',
      display: 'list-item',
      float: 'left',
      'z-index': 2
    });
    var evtData = {
      slideIndex: slideIndex
    };
    var duration = this.duration;
    var crossFade = this.crossFade;

    this._data.set('currentSlide', nextSlide);

    this._data.set('iteration', slideIndex);

    if (crossFade === true) {
      this._crossFadeTransition(currentSlide, nextSlide, duration, evtData);
    } else {
      this._fadeTransition(currentSlide, nextSlide, duration, evtData);
    }
  }

  _crossFadeTransition(currentSlide, nextSlide, duration, evtData) {
    this._transition(currentSlide, {
      opacity: 0,
      duration: duration - 50
    });

    setTimeout(() => {
      this._transition(nextSlide, {
        opacity: 1,
        duration: duration
      });
    }, 10);

    this._fireEvent('slide', evtData);
  }

  _fadeTransition(currentSlide, nextSlide, duration, evtData) {
    this._transition(currentSlide, {
      opacity: 0,
      duration: duration
    }, () => {
      this._transition(nextSlide, {
        opacity: 1,
        duration: duration
      });

      this._fireEvent('slide', evtData);
    });
  }
  /**
   * private method handles auto fade
   * @returns {boolean}
   * @private
   */


  _autoFadeSlide() {
    var MAX_COUNT = this.maxIntervals;
    var count = 0; //abort if nav has been manually invoked

    if (!this.auto) return false;

    var iteration = this._data.get('iteration');

    iteration = iteration < this._data.get('size') - 1 ? iteration + 1 : 0;

    this._updateIndicatorNav(iteration);

    this._FadeSlide(iteration);

    var timeoutId = setTimeout(() => {
      this._data.set('timeoutId', timeoutId);

      this._autoFadeSlide();

      if (MAX_COUNT) {
        count++;
        if (count > MAX_COUNT) clearTimeout(timeoutId);
      }
    }, this.autoInterval);
  }
  /**
   * private method that clears timeoutid for setTimeout
   * @private
   */


  _clearTimeout() {
    var timeoutId = this._data.get('timeoutId');

    var intervalId = this._data.get('intervalId');

    this.auto = false;

    if (timeoutId) {
      clearTimeout(timeoutId);
    } else if (intervalId) clearInterval(intervalId);
  }
  /**
   * private method that sets the transition duration
   * @private
   */


  _setDuration() {
    var animation = this.animation;

    if (animation === 'fade') {
      if (this._isTouch) this.duration = this.touchFadeDuration;else this.duration = this.desktopFadeDuration;
      if (!this.autoInterval) this.autoInterval = this._data.get('fadeAutoInterval');
    } else {
      if (!this.autoInterval) this.autoInterval = this._data.get('autoInterval');
      if (this._isTouch) this.duration = this.touchDuration;else this.duration = this.desktopDuration;
    }
  }
  /**
   * private method that returns the left position of requested li child
   * @param child
   @returns {int}
   * @private
   */


  _leftPos(child) {
    var innerContainer = this.element.find('carousel-inner-container');
    var innerContainerOffset = innerContainer.offset();
    var item = this.element.find(this._data.get('item') + ':' + child + '-child');
    var offset = item.offset();

    if (!offset) {
      console.log(item);
      console.log(child);
      console.log(this._data.get('item') + ':' + child + '-child');
      return 0;
    }

    var innerContainerOffsetLeft = innerContainerOffset.left;
    var scrollLeft = innerContainer.scrollLeft();
    offset.left -= innerContainerOffsetLeft - scrollLeft;
    return offset.left;
  }
  /**
   * private method that returns the inner container width
   * @returns {int}
   * @private
   */


  _innerContainerWidth() {
    var container = this._data.get('innerContainer');

    if (container === undefined) container = this.element.find('carousel-inner.container');
    return container.width();
  }

  _showNav(nav) {
    nav.removeClass('disabled');
    nav.addClass(this._data.get('visible'));
    nav.attr('style', '');
  }

  _hideNav(nav) {
    nav.addClass('disabled');
  }
  /**
   *
   */


  _enableNextNav() {
    return this._contentToSlide();
  }
  /**
   * private method that inits the nav controls
   * @private
   */


  _initNav() {
    var animation = this.animation;
    if (animation === 'multi-slide') this._initMultiSlideNav();else this.__initNav();
  }

  __initNav() {
    var outerContainer = this.element;
    var next = outerContainer.find(this.nextNavSelector);
    var prev = outerContainer.find(this.prevNavSelector);

    this._showNav(next);

    this._showNav(prev);

    this._data.set('nextNav', next);

    this._data.set('prevNav', prev);
  }

  _initMultiSlideNav() {
    var outerContainer = this.element;
    var next = outerContainer.find(this.nextNavSelector);
    var prev = outerContainer.find(this.prevNavSelector);

    this._data.set('nextNav', next);

    this._data.set('prevNav', prev);

    setTimeout(() => {
      if (this._enableNextNav()) this._showNav(next);else this._hideNav(next);

      this._hideNav(prev);
    }, 1500);
  }

  _initIndicatorNav() {
    var outerContainer = this.element;
    var indicators = outerContainer.find('carousel-navigation.indicators');

    var length = this._getSize();

    var strLi = '';

    for (var i = 0; i < length; i++) {
      if (i === 0) strLi += '<li class="active">&nbsp;</li>';else strLi += '<li>&nbsp;</li>';
    }

    indicators.append($(strLi));
    indicators.addClass('show');

    this._data.set('indicatorNav', indicators);

    this._indicatorEvents();
  }

  _setIndicatorNav() {
    var iteration = this._data.get('iteration');

    iteration = iteration !== undefined ? iteration : 0;

    var size = this._getSize();

    size--;

    if (iteration < size) {
      iteration++;

      this._updateIndicatorNav(iteration);
    } else this._clearTimeout();
  }

  _updateIndicatorNav(index) {
    var indicatorNav = this._data.get('indicatorNav');

    if (!indicatorNav) return false;
    indicatorNav.find('.active').removeClass('active');
    var items = indicatorNav.find('li');
    var item = items[index];
    if (item) $(item).addClass('active');
  }
  /**
   * private method that returns bool regarding not reaching the end of the hidden content to slide
   * @returns {boolean}
   * @private
   */


  _contentToSlide() {
    var child = 'last';

    var innerContainerWidth = this._innerContainerWidth();

    var left = this._leftPos(child);

    var width = this._slideDistanceFactor('last');

    left += width;
    left = left - 2;
    return left > innerContainerWidth;
  }
  /**
   * private method that returns the calculated width factor of the distance to slide
   * @param child
   * @returns {number}
   * @private
   */


  _slideDistanceFactor(child) {
    var distance = this.slideDistance;
    if (distance) return distance;else {
      var ele = this.element;
      var item;
      if (child === 'last' || child === 'first') item = ele.find(this._data.get('item') + ':' + child + '-child');else item = ele.find(this._data.get('item') + ':nth-child(' + child + ')');
      var width = item.outerWidth();
      var margin = item.css('margin-right');
      return parseInt(width) + parseInt(margin, 10);
    }
  }

  _isFirstSlide() {
    var iteration = this._data.get('iteration');

    iteration = iteration !== undefined ? iteration : 0;
    return iteration < 1;
  }

  _isLastSlide() {
    var iteration = this._data.get('iteration');

    iteration = iteration !== undefined ? iteration : 0;

    var size = this._getSize();

    iteration += 1;
    return iteration >= size;
  }
  /**
   * event trigger handler for selected slide
   * @param index
   * @param item
   * @private
   */


  _onSelected(index, item) {
    var url = 'url';
    var evtData = {
      index: index,
      url: item.attr(url),
      target: item
    };

    this._fireEvent('selected', evtData);
  }
  /**
   * private method for next slide request
   * @private
   */


  _onNext(index) {
    var animation = this.animation;
    var iteration = index;

    if (this._data.get('readyState')) {
      this._clearTimeout();

      this.auto = false;

      if (animation === 'fade') {
        /* fade */
        if (typeof iteration !== 'number') {
          iteration = this._data.get('iteration');
          iteration = iteration < this._data.get('size') - 1 ? iteration + 1 : 0;
        }

        this._FadeSlide(iteration);
      } else {
        /* slide mode */
        if (typeof iteration === 'number') {
          iteration--;

          this._data.set('iteration', iteration);
        }

        this._nextSlide();
      }
    }
  }
  /**
   * private method for prev slide request
   * @private
   */


  _onPrev(index) {
    var animation = this.animation;
    var iteration = index;

    if (this._data.get('readyState')) {
      this._clearTimeout();

      this.auto = false;

      if (animation === 'fade') {
        /* fade */
        if (typeof iteration !== 'number') {
          iteration = this._data.get('iteration');
          iteration = iteration > 0 ? iteration - 1 : this._data.get('size') - 1;
        }

        this._FadeSlide(iteration);
      } else {
        /* slide mode */
        if (typeof iteration === 'number') {
          iteration++;

          this._data.set('iteration', iteration);
        }

        this._prevSlide();
      }
    }
  }

  _pause() {
    this._clearTimeout();
  }
  /**
   *
   * @private
   */


  _enableNav() {
    var size = this.element.find(this._data.item).length;

    this._data.set('size', size);

    var next = this._data.get('nextNav');

    var prev = this._data.get('prevNav');

    if (size > 1) {
      next.show();
      prev.show();
    }
  }
  /**
   *
   * @private
   */


  _disableNav() {
    var next = this._data.get('nextNav');

    var prev = this._data.get('prevNav');

    next.hide();
    prev.hide();
  }
  /**
   * for fade transitions, show slide at index
   * @param index {Number}
   * @private
   */


  _onSlide(index) {
    var animation = this.animation;

    if (animation === 'fade') {
      /* fade */
      if (index < 0) index = 0;
      if (index > this._data.size - 1) index = this._data.size - 1;

      this._clearTimeout();

      this.auto = false;

      this._FadeSlide(index);
    } else {
      this._clearTimeout();

      this.auto = false;

      var currentIndex = this._data.get('iteration');

      if (index > currentIndex) this._onNext(index);else this._onPrev(index);

      this._updateIndicatorNav(index);
    }
  }

  _onOrientationChange() {
    var animation = this.animation;

    if (animation === 'slide') {
      this._data.set('iteration', 0);

      this.element.css({
        'margin-left': 0
      });
    }
  }

  _onIndicatorClick(event) {
    var indicatorNav = this._data.get('indicatorNav');

    var items = indicatorNav.find('li');
    var item = $(event.target);
    var index = items.index(item);

    var currentIndex = this._data.get('iteration');

    if (index > currentIndex) this._onNext(index);else this._onPrev(index);

    this._updateIndicatorNav(index);
  }
  /**
   * touch event handler
   * @param event {Object}
   * @param data {Object}
   * @private
   */


  _onGesture(event, data) {
    console.log(event.type);
    if (this.suspended) return;
    var animation = this.animation;
    var distance;
    if (data) distance = data.distance;

    switch (event.type) {
      case 'swiperight':
        event.preventDefault();

        if (this._isFirstSlide()) {
          this._resetToRunningOffsetPosition();

          return;
        }

        if (this._data.get('readyState')) {
          this._swipeExecuting = true;

          this._onPrev();

          this._currentOffset = 0;
        }

        break;

      case 'swipeleft':
        event.preventDefault();

        if (this._isLastSlide()) {
          this._swipeExecuting = true;

          this._resetToOffsetPositionForLastItem();

          return;
        }

        if (this._data.get('readyState')) {
          this._swipeExecuting = true;

          this._onNext();

          this._currentOffset = 0;
        }

        break;

      case 'tap':
        /*event.preventDefault();
        var item = $(event.target);
        var carouselItem;
        if (item[0].nodeName.toLowerCase() === 'carousel-item') carouselItem = item;
        else carouselItem = item.parents('carousel-item');
        this._onSelected(carouselItem.index(), carouselItem);*/
        break;

      case 'release':
        break;
    }
  }

  _onFingerDrag(event, data) {
    if (this.suspended) return;
    var animation = this.animation;
    if (animation === 'multi-slide' || animation === 'fade') return;

    var ele = this._data.get('itemsContainer');

    ele = ele[0];
    setTimeout(() => {
      if (!this._swipeExecuting) {
        var x = -1 * data.distance.x;
        this._currentOffset = x;
        x += parseInt(this._runningOffset);

        this._elementTranslate(ele, x);
      }
    }, 150);
  }

  _onFingerEnd(event, data) {//var animation=this.animation;
    //if (animation === 'multi-slide' || animation==='fade') return;
    //this._resetToRunningOffsetPosition();
  }

  _getItemsWidth() {
    if (this._itemsWidth > 0) return this._itemsWidth;else {
      var width = 0;

      var length = this._getSize();

      for (var i = 0; i < length; i++) {
        var child = i + 1;
        width += this._slideDistanceFactor(child);
      }

      return width;
    }
  }

  _resetToInitialPosition() {
    var ele = this._data.get('itemsContainer');

    ele.attr('style', '');
    this._runningOffset = 0;
    this._currentOffset = 0;
    this._swipeExecuting = false;

    this._data.set('iteration', 0);

    this._updateIndicatorNav(0);
  }

  _resetToLastPosition() {
    var ele = this._data.get('itemsContainer');

    var lastWidth = this._slideDistanceFactor('last');

    var width = this._getItemsWidth();

    width = width - lastWidth;
    var style = 'margin-left:-' + width + 'px';
    ele.attr('style', style);
    this._runningOffset = 0;
    this._currentOffset = 0;
    this._swipeExecuting = false;

    var size = this._getSize();

    var index = size - 1;

    this._data.set('iteration', index);

    this._updateIndicatorNav(index);
  }

  _resetToRunningOffsetPosition() {
    var animation = this.animation;
    if (animation === 'multi-slide' || animation === 'fade') return;

    var ele = this._data.get('itemsContainer');

    ele = ele[0];
    var x = this._runningOffset;

    this._elementTranslate(ele, x);
  }

  _resetToOffsetPositionForLastItem() {
    var animation = this.animation;
    if (animation === 'multi-slide' || animation === 'fade') return;

    var ele = this._data.get('itemsContainer');

    ele = ele[0];
    var x = this._runningOffset;

    this._elementTranslate(ele, x);

    setTimeout(() => {
      this._swipeExecuting = false;
    }, 150);
  }

  _elementTranslate(ele, x) {
    ele.style.transform = "translate3d(" + x + "px, 0,0)";
  }
  /**
   * element events
   * @private
   */


  _events() {
    var isTouch = this._isNativeTouch;
    var element = this.element;
    var orientationEvent = this._orientationEvent;

    var next = this._data.get('nextNav');

    var prev = this._data.get('prevNav');

    if (this._data.get('size') < 1) {
      next.hide();
      prev.hide();
    }

    var click = this._press;
    if (next === undefined || prev === undefined) return;
    next.on(click, this._onNext.bind(this));
    prev.on(click, this._onPrev.bind(this));
    /* resize */

    $(window).on(orientationEvent, this._onOrientationChange.bind(this));
    /* touch swipe event handling */

    if (isTouch) {
      element.on('swipeleft swiperight tap', this._onGesture.bind(this));
      element.on('fingerdrag', this._onFingerDrag.bind(this));
      element.on('fingerend', this._onFingerEnd.bind(this));
    }
  }

  get _press() {
    var press = 'click';
    if (UtilityHelper.isTouch) press = 'tap';
    return press;
  }

  _indicatorEvents() {
    /* click special event name */
    var click = this._click;

    var indicatorNav = this._data.get('indicatorNav');

    var items = indicatorNav.find('li');
    items.on(click, event => {
      this._onIndicatorClick(event);
    });
  }

  _fireEvent(evt, data) {
    data.id = this.id;
    this.fire('ui-carousel-' + evt, data);
  }
  /*==========================================
     PUBLIC PROPERTIES
  *===========================================*/


  get minLength() {
    return this.getAttribute('min-length');
  }

  set minLength(val) {
    this.setAttribute('min-length', val);
  }

  get suspended() {
    return this._suspended;
  }

  set suspended(val) {
    this._suspended = val;
  }

  get id() {
    return this.getAttribute('id');
  }

  set id(val) {
    this.setAttribute('id', val);
  }

  get crossFade() {
    return this.getAttribute('cross-fade');
  }

  get maxIntervals() {
    return this.getAttribute('max-intervals');
  }

  get indicators() {
    return this.getAttribute('indicators');
  }

  get slideDistance() {
    return this.getAttribute('slide-distance');
  }

  get easing() {
    var easing = this.getAttribute('easing');
    if (easing) return easing;else return 'ease';
  }

  get touchDuration() {
    var duration = this.getAttribute('touch-duration');
    if (duration) return parseInt(duration);else return 350;
  }

  get touchFadeDuration() {
    var duration = this.getAttribute('touch-fade-duration');
    if (duration) return parseInt(duration);else return 0;
  }

  get desktopDuration() {
    var duration = this.getAttribute('desktop-duration');
    if (duration) return parseInt(duration);else return 350;
  }

  get desktopFadeDuration() {
    var duration = this.getAttribute('desktop-fade-duration');
    if (duration) return parseInt(duration);else return 1000;
  }

  get autoInterval() {
    var interval = this.getAttribute('auto-interval');
    if (interval) return parseInt(interval);else return this._data.get('autoInterval');
  }

  set autoInterval(val) {
    this._data.set('autoInterval', val);
  }

  get fadeAutoInterval() {
    var interval = this.getAttribute('fade-auto-interval');
    if (interval) return parseInt(interval);else return this._data.get('fadeAutoInterval');
  }

  set autoInterval(val) {
    this._data.set('fadeAutoInterval', val);
  }

  get auto() {
    var auto = this.getAttribute('auto');
    if (auto) return true;else return this._auto;
  }

  set auto(val) {
    this._auto = val;
  }

  get animation() {
    var animation = this.getAttribute('animation');
    if (animation) return animation;else return this._animation;
  }

  set animation(val) {
    this._animation = val;
  }

  get prevNavSelector() {
    var selector = this.getAttribute('prev-nav-selector');
    if (selector) return selector;else return 'carousel-navigation.prev';
  }

  get nextNavSelector() {
    var selector = this.getAttribute('next-nav-selector');
    if (selector) return selector;else return 'carousel-navigation.next';
  }
  /*==========================================
   PUBLIC METHODS
   *===========================================*/

  /**
   * @public api
   */


  show() {
    var container = this.element;
    container.show();
  }
  /**
   * @public api
   */


  hide() {
    var container = this.element;
    container.hide();
  }
  /**
   *
   * @param direction
   * @public api
   */


  slide(direction) {
    if (this.suspended) return;
    if (typeof direction === 'number') this._onSlide(direction);else direction === 'next' ? this._onNext() : this._onPrev();
  }

  slideFirst() {
    this._resetToInitialPosition();
  }

  slideLast() {
    this._resetToLastPosition();
  }
  /**
   * @public
   */


  enableNav() {
    this._enableNav();
  }
  /**
   * @public
   */


  disableNav() {
    this._disableNav();
  }

  pause() {
    this._pause();
  }

  suspend() {
    this.suspended = true;
  }

  resume() {
    this.suspended = false;
  }

  runInit() {
    this._initCarousel();
  }

  reinit() {
    this._initialization();

    this._initNav();
  }

  setItems(html) {
    if (!this._carouselItems) {
      this._carouselItems = this.querySelector('carousel-items');
    }

    this._carouselItems.innerHTML = html;
    setTimeout(() => {
      this._initialization();

      this._initNav();
    }, 500);
  }

}
customElements.define('ui-carousel', UiCarouselElement);