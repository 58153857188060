import UtilityHelper from '../utility-helper/utility-helper';
export default class MdDialogElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._setEvents();

    this.opened = false;
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _setCss(dWidth, dHeight) {
    this._setElement();

    var viewport = UtilityHelper.viewport;
    var mqTouch = false;

    if (viewport.height <= 640) {
      mqTouch = true;
    }

    if (mqTouch) {
      var viewportWidth = viewport.width;
      if (dWidth > viewportWidth) dWidth = viewportWidth - 20;
      var viewportHeight = viewport.height;
      if (dHeight > viewportHeight) dHeight = viewportHeight - 50;
    }

    var width = dWidth / 2;
    this.element.css('width', dWidth + 'px');
    this.element.css('left', 'calc(50% - ' + width + 'px)');
    var height = dHeight / 2;
    this.element.css('height', dHeight + 'px');
    this.element.css('top', 'calc(50% - ' + height + 'px)');
  }

  _setElement() {
    if (!this.element) this.element = $(this);
  }

  _setEvents() {
    var onClose = this.close.bind(this);

    var onAction = this._onAction.bind(this);

    $(this).on('click', '[md-dialog-cancel]', onClose);
    $(this).on('click', '[md-dialog-action]', onAction);
  }

  _removeEvents() {
    var onClose = this.close.bind(this);

    var onAction = this._onAction.bind(this);

    $(this).off('click', '[md-dialog-cancel]', onClose);
    $(this).off('click', '[md-dialog-action]', onAction);
  }

  _fireEvent(type, event) {
    this.fire('md-dialog-' + type, {
      target: event.target
    });
  }

  _onAction(event) {
    this._fireEvent('action', event);
  }

  get dialogBottom() {
    if (this._bottom) return this._bottom;else {
      this._bottom = this.querySelector('#dialogBottom');
      return this._bottom;
    }
  }

  get actionLabel() {
    if (this._actionLabel) return this._actionLabel;else {
      this._actionLabel = this.querySelector('#actionName');
      return this._actionLabel;
    }
  }

  set actionLabel(val) {
    if (this.actionLabel) {
      this.actionLabel.innerHTML = val;
    }
  }

  showBottom() {
    var bottom = this.dialogBottom;

    if (bottom) {
      bottom.classList.remove('hide');
    }
  }

  hideBottom() {
    var bottom = this._bottom;

    if (bottom) {
      bottom.classList.add('hide');
    }
  }

  open(width, height) {
    if (!this.opened) {
      this._setCss(width, height);

      this.element.css('display', 'block');
      this.opened = true;
    }
  }

  close() {
    if (this.opened) {
      this.setAttribute('style', '');
      this.opened = false;
      this.hideBottom();
      this.actionLabel = 'Submit';
    }
  }

  get content() {
    var content = this.querySelector('.content-container');
    return content.innerHTML;
  }

  set content(html) {
    var content = this.querySelector('.content-container');
    content.innerHTML = html;
  }

  setContent(html) {
    this.content = html;
  }

  getContent() {
    return this.content;
  }

  removeContent() {
    var content = this.querySelector('.content-container');
    content.innerHTML = '';
  }

}
customElements.define('md-dialog', MdDialogElement);