import UtilityHelper from '../utility-helper/utility-helper';
export default class AppToolbarElement extends HTMLElement {
  constructor() {
    super();
    this._revealActive = false;
    this._reveal = true;
    this._fixed = false;
    this._threshold = 60;
    this._touchThreshold = 55;
    this._componentThreshold = 60;
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._setProperties();

    if (this._reveal) {
      this._setScrollListener();

      setTimeout(() => {
        this._initializeRevealState();
      }, 500);
    } else {
      this._setFixedScrollListener();
    }
  }

  _setProperties() {
    if (this.getAttribute('fixed')) {
      this._reveal = false;
      this._fixed = true;
    }

    var disableRevealForDesktop = this.getAttribute('desktop-disable-reveal');
    var isDesktop = UtilityHelper.isMediaDesktop;

    if (disableRevealForDesktop && disableRevealForDesktop == 'true' && isDesktop) {
      this._reveal = false;
    }

    var theshold = this.getAttribute('threshold');
    var touchTheshold = this.getAttribute('touch-threshold');

    if (theshold) {
      this._threshold = parseInt(theshold);
    }

    if (touchTheshold) {
      this._touchThreshold = parseInt(touchTheshold);
    }

    if (UtilityHelper.isMediaTouch) {
      this._componentThreshold = this._touchThreshold;
    } else {
      this._componentThreshold = this._threshold;
    }
  }

  _initializeRevealState() {
    var y = window.scrollY;

    this._setStyle(0, 0);

    if (y > this._componentThreshold) {
      this._revealActive = true;
      this.classList.add('shadow');
    }
  }

  _setScrollListener() {
    var last_position = 0;
    var ticking = false;
    window.addEventListener('scroll', e => {
      var oldPosition = last_position;
      last_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this._fireEvent(last_position, oldPosition);

          this._handleScroll(oldPosition, last_position);

          ticking = false;
        });
        ticking = true;
      }
    });
  }

  _setFixedScrollListener() {
    var last_position = 0;
    var ticking = false;
    window.addEventListener('scroll', e => {
      var oldPosition = last_position;
      last_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this._fireEvent(last_position, oldPosition);

          ticking = false;
        });
        ticking = true;
      }
    });
  }

  _handleScroll(oldPosition, newPosition) {
    var threshold = this._componentThreshold;

    if (newPosition === 0) {
      this._revealActive = false;

      this._setStyle(0, 0);

      this.classList.remove('shadow');
    } else if (newPosition > oldPosition && newPosition <= threshold) {
      this._revealActive = false;

      this._setStyle(newPosition, 0);

      this.classList.remove('shadow');
    } else if (newPosition > oldPosition) {
      this._revealActive = false;

      this._setStyle(threshold, 0);

      this.classList.remove('shadow');
    } else if (oldPosition > newPosition && newPosition <= threshold && !this._revealActive) {
      this._revealActive = false;

      this._setStyle(newPosition, 0);

      this.classList.remove('shadow');
    } else if (oldPosition > newPosition && !this._revealActive) {
      this._revealActive = true;

      this._setStyle(0, 200);

      this.classList.add('shadow');
    }
  }

  _setStyle(y, t) {
    var sY = y.toString();
    if (y > 0) sY = '-' + y.toString();
    this.style = 'transition-duration: ' + t + 'ms; transform: translate3d(0px, ' + sY + 'px, 0px);';
  }

  _fireEvent(y, prevY) {
    this.fire('app-toolbar-scroll', {
      y: y,
      prevY: prevY
    });
  }

}
customElements.define('app-toolbar', AppToolbarElement);