import '@polymer/lit-element';
import { LitElement, html, property, customElement } from '@polymer/lit-element';
export default class MultiSlideCarouselElement extends LitElement {
  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.elementChildren = Array.from(this.children);
  }

  render() {
    return html`
         <ui-carousel animation="multi-slide" class="${this.cssClass}">
           <carousel-navigation class="prev disabled"></carousel-navigation>
           <carousel-navigation class="next nav-visible"></carousel-navigation>
           <carousel-navigation class="indicators"></carousel-navigation>
           <carousel-inner-container class="visible multi-border">
              <carousel-items style="width: 23000px;">
                 ${this.elementChildren}
              </carousel-items>
           </carousel-inner-container>
         </ui-carousel>
      `;
  }

  createRenderRoot() {
    return this;
  }

  get cssClass() {
    var css = this.getAttribute('css-class');

    if (!css) {
      css = 'default';
    }

    return css;
  }

}
customElements.define('multi-slide-carousel', MultiSlideCarouselElement);