import UtilityHelper from '../utility-helper/utility-helper';
export default class UiSelectElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._initComponent();
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _initComponent() {
    setTimeout(() => {
      this.select = this.querySelector('select');

      this._setId();

      this._setName();

      this._setDefaultValue();

      this._setEvents();
    }, 500);
  }

  _setEvents() {
    var onChange = this._onChange.bind(this);

    var select = this.select;
    select.addEventListener('change', onChange);
  }

  _removeEvents() {
    var onChange = this._onChange.bind(this);

    var select = this.select;
    if (select) select.removeEventListener('change', onChange);
  }

  _onChange(event) {
    this.fire('ui-select-change', {
      value: event.target.value,
      target: this,
      id: this.id,
      name: this.name
    });
  }

  _setId() {
    if (this.defaultId) {
      this.select.setAttribute('id', this.defaultId);
    }
  }

  _setName() {
    if (this.defaultName) {
      this.select.setAttribute('name', this.defaultName);
    }
  }

  _setDefaultValue() {
    if (this.defaultValue) {
      this.select.value = this.defaultValue;
    }
  }

  get value() {
    return this.select.value;
  }

  set value(val) {
    this.select.value = val;
  }

  get id() {
    return this.select.getAttribute('id');
  }

  get name() {
    return this.select.getAttribute('name');
  }

  get defaultId() {
    return this.dataset.id;
  }

  get defaultName() {
    return this.dataset.name;
  }

  get defaultValue() {
    return this.dataset.value;
  }

  clear() {
    this.select.value = '';
  }

}
customElements.define('ui-select', UiSelectElement);