import UtilityHelper from '../utility-helper/utility-helper';
import './alt-image';
import './alt-images';
import './alt-video';
import './item-detail';
import './item-images';
import './magic-zoom';
export default class ShoppingItemElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.element = $(this);
    this._click = UtilityHelper.clickEvent;

    this._setProperties();

    this.fire = UtilityHelper.fireEvent;

    this._setImages();

    this._setCarousel();

    this._events();
  }

  get carouselId() {
    return this.getAttribute('carousel-id');
  }

  get maxPollingIntervals() {
    var val = this.getAttribute('max-polling-intervals');
    if (val) return parseInt(val);else return 20;
  }

  get maxPollingIntervals() {
    var val = this.getAttribute('polling-interval');
    if (val) return parseInt(val);else return 300;
  }

  get images() {
    var images = this.getAttribute('images');
    if (images) return JSON.parse(images);else return null;
  }

  _setProperties() {
    this._image = null;
    this._images = null;
    this._loadedImages = null;
    this._altImages = null;
    this._video = null;
    this._videoSource = null;
    this._videoContainer = null;
    this._zoom = null;
    this.isTouch = UtilityHelper.isTouch;
    this.isMediaTouch = UtilityHelper.isMediaTouch;
  }

  _events() {
    var click = this._click;
    this.element.on(click, 'alt-image', this._onSelectAltImage.bind(this));
    this.element.on('ui-carousel-slide', this._onAltImageChange.bind(this));
    this.element.on('image-zoom-in', this._onZoomIn.bind(this));
    this.element.on('image-zoom-out', this._onZoomOut.bind(this));
  }

  _onSelectAltImage(event) {
    var target = $(event.currentTarget);
    var video = target.attr('data-video');

    if (video === 'true') {
      this._onSelectAltImageVideo(target);

      return;
    }

    var src = target.attr('data-src');
    var zoomSrc = target.attr('data-zoom-src');

    var image = this._getImage();

    var video = this._getVideo();

    if (video[0]) {
      video.addClass('hide');

      var videoContainer = this._getVideoContainer();

      videoContainer.addClass('hide');
    }

    var zoom = this._getZoom();

    if (zoom[0]) zoom.removeClass('hide');
    image.attr('src', src);
    image.attr('zoom-src', zoomSrc);

    this._fireEvent('selected', {
      src: src,
      zoomSrc: zoomSrc
    });

    this._clearActive();

    target.addClass('active');
    if (this.carouselId && this.isTouch) this._slideCarousel(target[0]);
  }

  _onSelectAltImageVideo(target) {
    var src = target.attr('data-src');
    var zoomSrc = target.attr('data-zoom-src');

    this._clearActive();

    target.addClass('active');
    if (this.carouselId && this.isTouch) this._slideCarousel(target[0]);else {
      var video = this._getVideo();

      var zoom = this._getZoom();

      if (zoom[0]) zoom.addClass('hide');
      video.removeClass('hide');

      var videoContainer = this._getVideoContainer();

      videoContainer.removeClass('hide');
      var player = video[0];
      var videoSource = this._videoSource;

      if (videoSource && videoSource[0]) {
        videoSource[0].src = src;
        player.load();
      }
    }
  }

  _onAltImageChange(event) {
    var detail = event.detail;
    var index = detail.slideIndex;
    var image = this.element.find('alt-image')[index];

    this._clearActive();

    image.classList.add('active');
  }

  _onZoomIn() {
    if (this._carousel.suspend) this._carousel.suspend();
  }

  _onZoomOut() {
    if (this._carousel.resume) this._carousel.resume();
  }

  _getImage() {
    if (!this._image) this._image = this.element.find('.main-image').find('[data-image]');
    return this._image;
  }

  _getZoom() {
    if (!this._zoom) this._zoom = this.element.find('.main-image');
    return this._zoom;
  }

  _getVideoContainer() {
    if (!this._videoContainer) this._videoContainer = this.element.find('.main-video-container');
    return this._videoContainer;
  }

  _getVideo() {
    if (!this._video) {
      this._video = this.element.find('[data-id="main-video"]');
      this._videoSource = this.element.find('[data-id="main-video-source"]');
    }

    return this._video;
  }

  _clearActive() {
    this.element.find('alt-image').removeClass('active');
  }

  _setImages() {
    if (this.images) {
      this._images = this.images;

      this._preloadItemImages();
    }
  }

  _getAltImagesLength() {
    if (!this._altImages) this._altImages = this.element.find('alt-image');
    return this._altImages.length;
  }

  _preloadItemImages() {
    var images = this._images;
    this._loadedImages = [];
    var loadedImages = this._loadedImages;
    images.forEach(function (src) {
      var image = new Image();
      image.src = src;
      $(image).bind('load', function (event) {
        loadedImages.push(image);
      });
    });
  }

  _getLoadedImage(src) {
    var loadedImages = this._loadedImages;

    if (loadedImages) {
      var loadedImage = null;

      for (var i = 0; i < loadedImages.length; i++) {
        var image = loadedImages[i];

        if (image.src.toLowerCase() === src.toLowerCase()) {
          image.setAttribute('zoom-image', '');
          image.setAttribute('data-image', '');
          loadedImage = image;
          break;
        }
      }

      return loadedImage;
    } else return null;
  }

  _setLoadedImage(img) {
    var image = this._getImage();

    image.replaceWith(img);
    this._image = $(img);
  }

  _pollLoadedImage(src) {
    var self = this;
    var delay = this.pollingInterval;
    var MAX = this.maxPollingIntervals;
    var count = 0;
    var intervalId = setInterval(function () {
      var image = self._getLoadedImage(src);

      if (image) {
        self._setLoadedImage(image);

        clearInterval(intervalId);
      } else {
        count++;

        if (count > MAX) {
          clearInterval(intervalId);
        }
      }
    }, delay);
  }

  _setCarousel() {
    if (this.carouselId) {
      this._carousel = document.querySelector('#' + this.carouselId);
    }
  }

  _slideCarousel(element) {
    var length = this._getAltImagesLength();

    var images = this._altImages;
    var index = images.index(element);
    var carousel = this._carousel;

    if (!carousel) {
      this._setCarousel();

      carousel = this._carousel;
    }

    if (index === 0) carousel.slideFirst();else if (index === length - 1) carousel.slideLast();else carousel.slide(index);
  }

  _fireEvent(evt, data) {
    this.fire('item-image-' + evt, data);
  }

  setImage(src) {
    var image = this._getImage();

    image.attr('src', src);
  }

  setPreloadedImage(src) {
    if (!this._images) return;

    var image = this._getLoadedImage(src);

    if (!image) {
      this._pollLoadedImage(src);
    } else this._setLoadedImage(image);
  }

}
customElements.define('shopping-item', ShoppingItemElement);